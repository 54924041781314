import React from "react"
import classnames from "classnames"

export default function Container({ children, className, ...props }) {
  return (
    <div
      {...props}
      className={classnames(
        className,
        "max-w-screen-lg",
        "mx-auto",
        "px-8",
        "md:px-12"
      )}
    >
      {children}
    </div>
  )
}
