import React from "react"
import classnames from "classnames"

export default function Container({
  children,
  className,
  lessVerticalSpace,
  ...props
}) {
  return (
    <div
      {...props}
      className={classnames(
        className,
        "xl:container",
        "xl:mx-auto",
        "px-8",
        lessVerticalSpace ? "py-5" : "py-10",
        "md:px-12",
        lessVerticalSpace ? "sm:py-10" : "sm:py-20"
      )}
    >
      {children}
    </div>
  )
}
