import React from "react"

export default function HeaderTitle({ title, subtitle }) {
  return (
    <>
      <h1 className="font-display text-gray-700 text-xl mb-2 font-bold text-center sm:text-left">
        {title}
      </h1>
      <h2 className="text-gray-900 text-2xl leading-tight font-bold sm:text-4xl text-center sm:text-left">
        {subtitle}
      </h2>
    </>
  )
}
