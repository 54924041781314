import React from "react"
import ClockIcon from "../../icons/ClockIcon"
import TextSectionLink from "../TextContainer/Link"

export default function TableOfContentsRow({ title, duration, previewLink }) {
  return (
    <div className="flex lg:ml-12 ml-6 items-center md:mb-4 mb-5 lg:w-3/4">
      <div className="w-full pr-8">
        {previewLink ? (
          <TextSectionLink href={previewLink} title="Preview">
            {title}
          </TextSectionLink>
        ) : (
          title
        )}
      </div>
      <div className="flex items-center tabular-nums justify-end">
        <div className="mr-2">{duration}</div>
        <ClockIcon />
      </div>
    </div>
  )
}
