import React from "react"
import classnames from "classnames"
import { Link } from "gatsby"

export default function TextSectionLink({
  href,
  light,
  notBlank,
  children,
  local,
  big,
  ...props
}) {
  const textColor = light ? "text-blue-300" : "text-blue-900"
  const textColorHover = light ? "hover:text-blue-100" : "hover:text-blue-700"
  const textSize = big ? "text-xl" : ""
  const borderSize = big ? "border-b-4" : "border-b-2"
  const classes = classnames(
    textColorHover,
    textSize,
    textColor,
    borderSize,
    "font-bold",
    "border-blue-500",
    "hover:border-blue-300"
  )
  return local ? (
    <Link {...props} className={classes} to={href}>
      {children}
    </Link>
  ) : (
    <a
      {...props}
      href={href}
      target={notBlank ? undefined : "_blank"}
      rel={notBlank ? undefined : "noreferrer"}
      className={classes}
    >
      {children}
    </a>
  )
}
