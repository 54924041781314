import React from "react"
import Head from "../components/Head"
import Container from "../components/Container.js"
import HeaderTitle from "../components/HeaderTitle.js"
import { Link } from "gatsby"
import TableOfContents from "../components/TableOfContents"
import TableOfContentsHeader from "../components/TableOfContents/Header"
import TableOfContentsRow from "../components/TableOfContents/Row"
import TableOfContentsSection from "../components/TableOfContents/Section"
import TextSectionLink from "../components/TextContainer/Link"

export default function BeginnerTableOfContents() {
  return (
    <>
      <Head />
      <Container lessVerticalSpace>
        <HeaderTitle
          title={<Link to="/#beginner-course">JavaScript Simplified</Link>}
          subtitle="Beginner Course"
        />
      </Container>
      <TableOfContents>
        <TableOfContentsSection>
          <TableOfContentsHeader>Mindset</TableOfContentsHeader>
          <TableOfContentsRow duration="5:31" title="Course Introduction" />
          <TableOfContentsRow duration="4:54" title="JavaScript Is Hard" />
          <TableOfContentsRow
            duration="4:44"
            title="Don't Get Discouraged"
            previewLink="https://courses.webdevsimplified.com/courses/javascript-simplified-beginner/521730-Mindset/1510473-03-Don't-Get-Discouraged"
          />
        </TableOfContentsSection>
        <TableOfContentsSection>
          <TableOfContentsHeader>Setup</TableOfContentsHeader>
          <TableOfContentsRow duration="3:42" title="What Is JavaScript?" />
          <TableOfContentsRow duration="3:02" title="Download Tools" />
          <TableOfContentsRow
            duration="7:54"
            title="Your First JavaScript App"
          />
          <TableOfContentsRow duration="5:45" title="Prettier" />
        </TableOfContentsSection>
        <TableOfContentsSection>
          <TableOfContentsHeader>Basic Variables</TableOfContentsHeader>
          <TableOfContentsRow duration="9:52" title="First Variable" />
          <TableOfContentsRow duration="7:10" title="Number Type" />
          <TableOfContentsRow duration="6:13" title="String Type" />
          <TableOfContentsRow duration="6:43" title="Boolean Type" />
          <TableOfContentsRow duration="4:09" title="Null And Undefined Type" />
          <TableOfContentsRow duration="7:44" title="Variable Comparison" />
          <TableOfContentsRow duration="3:56" title="Garbage Collection" />
          <TableOfContentsRow duration="5:16" title="Comments" />
        </TableOfContentsSection>
        <TableOfContentsSection>
          <TableOfContentsHeader>Functions/Scoping</TableOfContentsHeader>
          <TableOfContentsRow duration="19:46" title="Normal Functions" />
          <TableOfContentsRow
            duration="13:12"
            title="Passing Functions As Arguments"
          />
          <TableOfContentsRow duration="12:29" title="Arrow Functions" />
          <TableOfContentsRow
            duration="8:11"
            title="Stack Trace And Call Stack"
            previewLink="https://courses.webdevsimplified.com/courses/javascript-simplified-beginner/521734-Functions-Scoping/1510574-19-Stack-Trace-And-Call-Stack"
          />
          <TableOfContentsRow duration="4:23" title="Hoisting" />
          <TableOfContentsRow duration="9:04" title="Scoping" />
          <TableOfContentsRow duration="4:35" title="Closures" />
        </TableOfContentsSection>
        <TableOfContentsSection>
          <TableOfContentsHeader>Advanced Variables</TableOfContentsHeader>
          <TableOfContentsRow duration="4:21" title="Const" />
          <TableOfContentsRow duration="4:20" title="Var" />
          <TableOfContentsRow duration="6:31" title="Type Coercion" />
          <TableOfContentsRow duration="6:33" title="== Vs ===" />
          <TableOfContentsRow duration="2:58" title="NaN" />
          <TableOfContentsRow duration="11:53" title="Arrays" />
          <TableOfContentsRow duration="17:52" title="Objects" />
          <TableOfContentsRow duration="17:08" title="Reference Vs Value" />
          <TableOfContentsRow duration="13:44" title="Array Methods" />
          <TableOfContentsRow
            duration="4:03"
            title="String Template Literals"
          />
          <TableOfContentsRow duration="10:39" title="New And This" />
        </TableOfContentsSection>
        <TableOfContentsSection>
          <TableOfContentsHeader>Basic DOM</TableOfContentsHeader>
          <TableOfContentsRow duration="11:40" title="Async Vs Defer" />
          <TableOfContentsRow duration="3:58" title="Window" />
          <TableOfContentsRow duration="2:54" title="Document" />
          <TableOfContentsRow duration="6:58" title="Id And Class Selectors" />
          <TableOfContentsRow duration="6:02" title="Query Selectors" />
          <TableOfContentsRow duration="13:33" title="Event Listeners" />
          <TableOfContentsRow
            duration="5:07"
            title="Additional Differences Between Arrow And Normal Functions"
          />
          <TableOfContentsRow duration="5:48" title="Data Attributes" />
          <TableOfContentsRow duration="9:45" title="DOM Traversal" />
          <TableOfContentsRow
            duration="8:26"
            title="Simple Todo List"
            previewLink="https://courses.webdevsimplified.com/courses/javascript-simplified-beginner/521736-Basic-DOM/1510717-43-Simple-Todo-List"
          />
          <TableOfContentsRow duration="1:44" title="Modal Introduction" />
          <TableOfContentsRow
            duration="5:48"
            title="Modal Walkthrough"
            previewLink="https://courses.webdevsimplified.com/courses/javascript-simplified-beginner/521736-Basic-DOM/1510725-45-Modal-Walkthrough"
          />
        </TableOfContentsSection>
        <TableOfContentsSection>
          <TableOfContentsHeader>Control Flow</TableOfContentsHeader>
          <TableOfContentsRow duration="16:38" title="If" />
          <TableOfContentsRow duration="8:01" title="Ternary Operator" />
          <TableOfContentsRow duration="12:35" title="Switch Statement" />
          <TableOfContentsRow duration="14:29" title="For Loop" />
          <TableOfContentsRow duration="6:22" title="While Loop" />
          <TableOfContentsRow duration="13:06" title="Recursion" />
          <TableOfContentsRow
            duration="9:38"
            title="Short Circuit Evaluation"
          />
          <TableOfContentsRow duration="22:18" title="Midi Piano" />
          <TableOfContentsRow duration="1:23" title="Quiz App Introduction" />
          <TableOfContentsRow duration="15:08" title="Quiz App Walkthrough" />
          <TableOfContentsRow
            duration="1:42"
            title="Form Validation Introduction"
          />
          <TableOfContentsRow
            duration="14:54"
            title="Form Validation Walkthrough"
          />
        </TableOfContentsSection>
        <TableOfContentsSection>
          <TableOfContentsHeader>Asynchronous Code</TableOfContentsHeader>
          <TableOfContentsRow duration="6:28" title="Callbacks" />
          <TableOfContentsRow duration="23:21" title="Promises" />
          <TableOfContentsRow duration="15:35" title="Async Await" />
        </TableOfContentsSection>
        <TableOfContentsSection>
          <TableOfContentsHeader>Advanced DOM</TableOfContentsHeader>
          <TableOfContentsRow duration="19:59" title="Fetch" />
          <TableOfContentsRow duration="14:12" title="Event Loop" />
          <TableOfContentsRow duration="12:23" title="Event Delegation" />
          <TableOfContentsRow duration="15:14" title="Browser Storage" />
          <TableOfContentsRow duration="39:57" title="Advanced Todo List" />
          <TableOfContentsRow
            duration="1:37"
            title="Expand Collapse Introduction"
          />
          <TableOfContentsRow
            duration="6:30"
            title="Expand Collapse Walkthrough"
          />
          <TableOfContentsRow duration="15:52" title="Google Maps Clone" />
        </TableOfContentsSection>
        <TableOfContentsSection>
          <TableOfContentsHeader>Modules/Bundlers</TableOfContentsHeader>
          <TableOfContentsRow duration="12:18" title="ES6 Modules" />
          <TableOfContentsRow duration="5:34" title="Node Modules" />
          <TableOfContentsRow duration="10:48" title="NPM" />
          <TableOfContentsRow duration="15:10" title="Parcel" />
          <TableOfContentsRow duration="31:20" title="Date Picker" />
          <TableOfContentsRow duration="1:10:08" title="Shopping Cart" />
        </TableOfContentsSection>

        <div className="mt-12">
          <div className="mb-3">
            <TextSectionLink local href="/advanced-table-of-contents">
              Advanced Course Video List
            </TextSectionLink>
          </div>
          <div className="mb-3">
            <TextSectionLink local href="/interview-table-of-contents">
              Interviews Video List
            </TextSectionLink>
          </div>
          <div>
            <TextSectionLink local href="/bonus-projects-table-of-contents">
              Bonus Projects Video List
            </TextSectionLink>
          </div>
        </div>
      </TableOfContents>
      <div className="my-12 text-2xl text-center">
        <TextSectionLink local href="/#beginner-course">
          Back To Landing Page
        </TextSectionLink>
      </div>
    </>
  )
}
