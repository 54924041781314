import React from "react"

export default function ClockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      className="w-5 h-5"
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        className="text-blue-500"
      />
      <path
        className="text-blue-500"
        fill="currentColor"
        d="M13 11.59l3.2 3.2a1 1 0 0 1-1.4 1.42l-3.5-3.5A1 1 0 0 1 11 12V7a1 1 0 0 1 2 0v4.59z"
      />
    </svg>
  )
}
